<template>
  <div class="contents-section">
    <div class="title-section">
      <h2>직원등록</h2>
    </div>
    <div class="appendBtnWrap mb-2">
      <div>
        <v-tooltip bottom color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              link
              depressed
              rounded
              min-width="40"
              width="60"
              height="40"
              @click="rowsAppendFunc()"
            >
              <v-icon size="20" left>
                mdi-plus
              </v-icon>
              <p class="btnText">1</p>
            </v-btn>
          </template>
          <span>열 1개 추가</span>
        </v-tooltip>
        <v-tooltip bottom color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              link
              depressed
              rounded
              min-width="40"
              width="70"
              height="40"
              class="ml-2"
              @click="rowsAppendFunc(10)"
            >
              <v-icon size="20" left>
                mdi-plus
              </v-icon>
              <p class="btnText">10</p>
            </v-btn>
          </template>
          <span>열 10개 추가</span>
        </v-tooltip>
      </div>
      <div class="countWrap">
        <p class="mr-4">총 열수 : {{ rows.length }}</p>
      </div>
    </div>
    <div class="registWrap">
      <div
        class="itemRow"
        v-for="(item, index) in rows"
        :key="index"
      >
        <div class="mb-7 mr-0 radioWrap">
          <v-radio-group row v-model="item.isSales">
            <v-radio
              label="일반"
              :value="false"
              dense
            ></v-radio>
            <v-radio
              label="영업"
              :value="true"
              dense
            ></v-radio>
          </v-radio-group>
        </div>
        <v-text-field
          label="아이디"
          class="mr-2"
          outlined
          dense
          v-model="item.id"
          :rules="[idRule]"
          color="primary"
          background-color="#FFFFFF"
        ></v-text-field>
        <v-text-field
          class="mr-2"
          outlined
          dense
          label="비밀번호"
          :rules="[passwordRule]"
          :persistent-hint="true"
          :hint="item.id && idRule(item.id) === true && item.password === ''?`기본: ${item.id}!@`:''"
          v-model="item.password"
          color="primary"
          background-color="#FFFFFF"
        ></v-text-field>
        <v-text-field
          :persistent-hint="true"
          label="이름"
          class="mr-2 nameTextBox"
          outlined
          dense
          :rules="[nameRule]"
          v-model="item.name"
          color="primary"
          background-color="#FFFFFF"
        ></v-text-field>
        <v-text-field
          :persistent-hint="true"
          label="직위/직책"
          class="mr-2 nameTextBox"
          outlined
          :rules="[nameRule]"
          dense
          v-model="item.position"
          color="primary"
          background-color="#FFFFFF"
        ></v-text-field>
        <v-menu
          v-model="item.datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :persistent-hint="true"
              label="입사일"
              v-model="item.date"
              readonly
              v-bind="attrs"
              outlined
              dense
              class="mr-2 dateBox"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.date"
            @input="item.datePicker = false"
          ></v-date-picker>
        </v-menu>
        <div class="mb-6 mr-2">
          <v-btn
            depressed
            height="40"
            color="secondary"
            @click="dialogOpenFunc(index, 'permission')"
          >
            {{
              item.permissionName === null
              ? '권한설정' : item.permissionName
            }}
          </v-btn>
        </div>
        <div class="mb-6 mr-2">
          <v-btn
            depressed
            height="40"
            color="accent"
            @click="dialogOpenFunc(index, 'group')"
          >{{ item.group.groupNo === null?'그룹설정':item.group.groupName }}</v-btn>
        </div>
        <div class="mb-6">
          <v-tooltip bottom color="black" v-if="rows.length !== 1">
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                link
                depressed
                rounded
                min-width="40"
                width="40"
                height="40"
                @click="rows.splice(index, 1); dialogIndex = 0"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>열 삭제</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="btnWrap mt-8 mb-15">
      <v-btn
        depressed
        color="primary"
        @click="registAccounts"
      >
        {{ rows.length }}건 등록하기
      </v-btn>
    </div>
    <dlg-user-group
      :show.sync="dialog.group.show"
      :groups="groups"
      :open="dialog.group.open"
      :active="dialog.group.active"
      :func="setGroupFunc"
      :type="3"
    />
    <dlg-user-auth
      :show.sync="dialog.permission.show"
      :type="4"
      :permission="dialog.permission.permission"
      :presets="presets"
      :func="setAuthFunc"
    />
    <v-dialog
      v-model="dialog.result.show"
      persistent
      scrollable
      max-width="500"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">직원등록결과</span>
          <v-chip small class="ml-2" color="success">성공: {{ dialog.result.successCnt }}건</v-chip>
          <v-chip small class="ml-1" color="error">실패: {{ dialog.result.failCnt }}건</v-chip>
        </v-card-title>
        <v-card-text>
          <v-simple-table ref="resultTable">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    이름(아이디)
                  </th>
                  <th class="text-left">
                    비밀번호
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dialog.result.data"
                  :key="item.id"
                >
                  <td>{{ item.name }}({{ item.id }})</td>
                  <td>{{ item.newPassword }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-6">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="resultTableCopy"
          >
            <v-icon small class="mr-1">mdi-content-copy</v-icon>
            결과복사하기
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="dialog.result.show = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DlgUserAuth from '@/components/dialog/DlgUserAuth.vue';
import DlgUserGroup from '@/components/dialog/DlgUserGroup.vue';
import time from '@/util/time';

export default {
  components: { DlgUserAuth, DlgUserGroup },
  data: () => ({
    dialogIndex: 0,
    defaultPreset: {},
    groups: [],
    presets: [],
    dialog: {
      permission: {
        show: false,
        permission: {},
      },
      group: {
        show: false,
        active: [],
        open: [],
      },
      result: {
        show: false,
        successCnt: 0,
        failCnt: 0,
        data: [],
      },
    },
    defaultItem: {
      isSales: false,
      id: '',
      password: '',
      name: '',
      position: '',
      date: time.moment(new Date()).format('YYYY-MM-DD'),
      datePicker: false,
      group: {
        groupNo: null,
        open: [],
        active: [],
      },
      permission: {},
    },
    rows: [
      {
        isSales: false,
        id: '',
        password: '',
        name: '',
        position: '',
        date: time.moment(new Date()).format('YYYY-MM-DD'),
        datePicker: false,
        group: {
          groupNo: null,
          open: [],
          active: [],
        },
        permission: {},
        permissionName: null,
      },
    ],
    idRule: (value) => {
      const reg = /^[a-z0-9]{3,15}$/g;
      return reg.test(value) || '3~15자 영문소문자, 숫자';
    },
    passwordRule: (value) => {
      const reg = /^[a-z]+[a-z0-9!@#$%^&*]{4,20}$/g;
      let check = true;
      if (!reg.test(value) && value !== '') {
        check = '5~20자 영문소문자, 숫자, 특수문자';
      }
      return check;
    },
    nameRule: (value) => {
      const reg = /^[a-zA-Z가-힣ㄱ-ㅎ0-9]{1,15}$/g;
      return reg.test(value) || '1~15자 한글, 영문, 숫자';
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapActions({
      alert: 'dialog/alert',
    }),
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    rowsDeleteFunc(seq) {
      const newRows = this.rows.filter((item) => item.seq !== seq);
      this.rows = newRows;
    },
    rowsAppendFunc(num = 1) {
      for (let i = 0; i < num; i += 1) {
        if (this.rows.length < 30) {
          this.rows.push({ ...this.defaultItem });
        } else {
          this.alert(['error', '한번에 최대 30명까지 등록 가능합니다.']);
        }
      }
    },
    dialogOpenFunc(i, type) {
      this.dialogIndex = i;
      if (type === 'group') {
        this.$socket.emit('groups.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: ['no'],
          sortDesc: [false],
          filters: [],
        }, (resp) => {
          if (resp.result === 'success') {
            this.groups = resp.items.groups;
            this.dialog.group.show = true;
            this.dialog.group.open = this.rows[i].group.open;
            this.dialog.group.active = this.rows[i].group.active;
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      } else if (type === 'permission') {
        this.$socket.emit('permissions.preset.list.get', {
          page: 1,
          itemsPerPage: 0,
          sortBy: [],
          sortDesc: [],
          filters: [],
        }, (resp) => {
          if (resp.result === 'success') {
            const presets = resp.items.filter((r) => r.no !== 1);
            const defaultPreset = resp.items.filter((r) => r.no === 1)[0].permission;
            this.presets = presets;
            this.defaultPreset = defaultPreset;
            if (
              Object.keys(this.rows[i].permission).length !== 0
              && this.rows[i].permission != null
            ) {
              this.dialog.permission.permission = this.rows[i].permission;
            } else {
              this.dialog.permission.permission = defaultPreset;
            }
            this.dialog.permission.show = true;
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`], false);
            console.error(resp);
          }
        });
      }
    },
    setAuthFunc(permission, name) {
      console.log(permission);
      this.rows[this.dialogIndex].permission = permission;
      this.rows[this.dialogIndex].permissionName = name;
    },
    setGroupFunc(groupNo, groupName, active, open) {
      this.rows[this.dialogIndex].group = {
        groupNo,
        groupName,
        active,
        open,
      };
    },
    registAccounts() {
      this.progress('true');
      const reqObj = { items: [] };
      let checkVal = true;
      for (let i = 0; i < this.rows.length; i += 1) {
        if (
          this.idRule(this.rows[i].id) !== true
          || this.passwordRule(this.rows[i].password) !== true
          || this.nameRule(this.rows[i].name) !== true
          || this.nameRule(this.rows[i].position) !== true
        ) {
          checkVal = false;
        } else {
          const rowObj = {
            id: this.rows[i].id,
            newPassword: this.rows[i].password === '' ? `${this.rows[i].id}!@` : this.rows[i].password,
            name: this.rows[i].name,
            groupNo: this.rows[i].group.groupNo,
            permission:
              Object.keys(this.rows[i].permission).length === 0
                ? this.defaultPreset : this.rows[i].permission,
            isSales: this.rows[i].isSales,
            position: this.rows[i].position,
            date: this.rows[i].date,
          };
          reqObj.items.push(rowObj);
        }
      }
      if (checkVal === false) {
        this.alert(['error', '입력정보를 확인해주세요.']);
        this.progress('false');
      } else {
        this.$socket.emit('users.staffs.add', reqObj, (resp) => {
          if (resp.success > 0) {
            this.alert(['success', '등록이 완료되었습니다.']);
            if (resp.fails > 0) {
              reqObj.items.forEach((row, index) => {
                const fails = resp.failsInfo.filter((r) => row.id === r.id);
                if (fails.length > 0) {
                  reqObj.items.splice(index, 1);
                }
              });
            }
            this.dialog.result.data = reqObj.items;
            this.dialog.result.failCnt = resp.fails;
            this.dialog.result.successCnt = resp.success;
            this.dialog.result.show = true;
          } else if (resp.success === 0 && resp.fails > 0) {
            this.alert(['error', '입력정보를 확인해주세요. 중복아이디는 허용되지 않습니다.']);
            console.error(resp);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
          this.progress('false');
        });
      }
    },
    resultTableCopy() {
      const table = this.$refs.resultTable.$el.innerText;
      navigator.clipboard.writeText(table);
      this.alert(['success', '클립보드에 복사되었습니다.']);
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.admin.menu.account.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          if (!this.checkFuncPermission('regist')) {
            this.alert(['error', '잘못된 접근입니다.']);
            this.$router.push('/');
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.registWrap {
  .itemRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:-25px;
    .radioWrap {
      flex-shrink: 0;
    }
  }
}
.appendBtnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btnText {
    font-size: 1.1rem;
    margin-bottom:2px;
  }
  .countWrap {
    display: flex;
    p{
      margin:0;
      font-size: .9rem;
    }
  }
}
.btnWrap {
  display: flex;
  justify-content: center;
}
</style>
